<template>
  <v-layout wrap class="justify-center">
    <v-flex lg12 md12 sm12 pr-2>
      <v-container container--fluid>
        <v-layout>
          <v-breadcrumbs
            style="padding-left: 0px; padding-top: 5px; padding-bottom: 5px;"
            :items="breadcrumbs"
            divider="/"
          ></v-breadcrumbs>

        </v-layout>
        <v-layout>
          <page-title>Transactions</page-title>

          <div class="ml-4 mt-1 mb-2">
            <v-btn class="secondary--text mt-2 mr-3" text outlined small @click="refresh()">
              <v-icon small class="mr-1">refresh</v-icon>
              Refresh
            </v-btn>
          </div>
        </v-layout>

        <v-layout class="mt-4">
          <p class="secondary--text caption" v-if="model">View transactions for the model <strong>{{ model.name }}</strong>. 
            Credits are charged to the user's account when they make a prediction.
          </p>
        </v-layout>

        <!-- Error message -->
        <v-layout>
          <div v-if="error">
            <v-alert type="error" dense outlined>
              {{ error }}
            </v-alert>
          </div>
        </v-layout>
        
        <v-layout class="mt-2 mb-4">          
          <v-flex lg12 md12 sm12>
            <v-card >
              <v-card-title>
                <span class="secondary--text text-subtitle-1">
                  Daily Charges ({{Math.abs(this.dailyCostsTotal.toFixed(2))}} credits)
                </span>
              </v-card-title>
              <div class="pt-2 mb-2" id="frost-chart" ></div>
            </v-card>
          </v-flex>

        </v-layout>                
        <v-layout class="mt-4">        
          <div class="tbcard" v-if="model">
            <v-card>
              <v-card-title>
                <span class="secondary--text text-subtitle-1">
                  Activity
                </span>
              </v-card-title>
              <v-data-table :key="tableKey" :headers="headers" :items="items"
                hide-default-footer :search="e3" :loading="loading" :server-items-length="pagination.totalItems"
                loading-text="Loading... Please wait">

                <template v-slot:top>
                  <v-row>
                    <v-layout wrap class="justify-end mt-5 mr-5 ml-5">

                    </v-layout>
                  </v-row>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.createdAt | ago }}
                      </span>
                    </template>
                    <span>{{ item.createdAt | date }}</span>
                  </v-tooltip>
                </template>          

                <template v-slot:[`item.endUserId`]="{ item }">
                  <router-link  class="no-underline" :to="{
                      name: 'userDetails',                                
                      params: {
                        userId: item.endUserId,
                      },
                    }">
                    {{ item.endUserId }}
                  </router-link>
                </template>
                
                <template v-slot:[`item.amount`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.amount.toFixed(4) }}
                      </span>
                    </template>
                    <span>{{ item.amount }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{  }">
                  <v-menu class="items" top left transition="v-slide-y-transition">
                    <template v-slot:activator="{ on: on }">
                      <v-btn icon v-on="on" :class="
                        $store.state.theme === 'light' ? 'secondary--text' : ''
                      ">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-title disabled>user details</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title disabled>revert transaction</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
              <div class="pr-2 pb-2">
                <v-layout class="mt-2">
                  <v-spacer></v-spacer>
                  <span class="text-caption mt-3 mr-4">Rows per page:</span>
                  <div class="rows-select">
                    <v-flex shrink class="select">
                      <v-select class="mb-2" width="25" v-model="itemsPerPage" :items="itemsPerPageOptions" dense
                        label="Rows per page" single-line></v-select>
                    </v-flex>
                  </div>
                  <span class="text-caption mt-3 mr-4 ml-4">Total items: {{ pagination.totalItems }}</span>
                  <v-btn class="mr-2 mt-2" icon small router :disabled="pagination.previousPageToken == '' ? true : false"
                    :to="{
                      name: 'transactions',
                      params: {
                        modelId: model.id,
                      },
                      query: {
                        pageToken: pagination.previousPageToken,
                      },
                    }">
                    <v-icon>navigate_before</v-icon>
                  </v-btn>
                  <!-- Navigate to the next page based on the token -->
                  <v-btn class="ml-2 mt-2" icon small router :disabled="pagination.nextPageToken == '' ? true : false"
                    :to="{
                      name: 'transactions',
                      params: {
                        modelId: model.id,
                      },
                      query: {
                        pageToken: pagination.nextPageToken,
                      },
                    }">
                    <v-icon>navigate_next</v-icon>
                  </v-btn>
                </v-layout>
              </div>
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import { Chart } from "frappe-charts"

import PageTitle from '../PageTitle';

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      user: '',
      itemsPerPage: 10,
      itemsPerPageOptions: [
        10,
        20,
        200,
        500,
      ],
    }
  },

  watch: {
    // Table pagination
    itemsPerPage: {
      handler() {
        // If user is changing items per page, we kinda need
        // to reset the token, otherwise going backwards might
        // be weird
        let reset = this.itemsPerPage !== this.pagination.pageSize ? true : false
        this.$store.dispatch('SetEndUserTransactionsPageSize', this.itemsPerPage).then(() => {
          
        if (reset && this.pageTokenFromUrl) {
          this.$router.push({ 
            name: 'transactions',
            params: {
              modelId: this.modelId,
            },
          }).catch(() => { })
        } else {
          this.loadItems()
        }
        })
      }
    },
    status: {
      handler() {
        this.loadItems()
      }
    },
    $route() {
      this.loadItems()
    }
  },

  computed: {
    modelId() {
      return this.$route.params.modelId
    },
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    pageTokenFromUrl() {
      return this.$route.query.pageToken
    },
    model() {
      return this.$store.state.model.model
    },
    loading() {
      return this.$store.state.endUserTransactions.loading
    },
    error() {
      return this.$store.state.endUserTransactions.error
    },
    items() {
      return this.$store.state.endUserTransactions.transactions
    }, 
    dailyCosts() {
      return this.$store.state.endUserTransactions.dailyCosts
    },
    dailyCostsTotal() {
      return this.$store.state.endUserTransactions.dailyCostsTotal
    },
    headers() {
      return [
        { text: 'Transaction Time', align: 'left', value: 'createdAt', sortable: false },
        { text: 'User', value: 'endUserId', align: 'left' },
        { text: 'Amount', value: 'amount', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' } 
      ]
    },
    pagination() {
      return this.$store.state.endUserTransactions.pagination
    },
    breadcrumbs() {      
      if (!this.model) {
        return []
      }
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: this.model.name,
          disabled: false,
          href: `/models/${this.modelId}`,     
        },
        {
          text: 'Transactions',
          disabled: true,
          href: '',
        },
      ]
    }
  },

  async mounted() {
    this.itemsPerPage = this.pagination.pageSize

    this.refresh()    
  },

  methods: {
    async refresh() {
      // Loading user for the project
      await this.$store.dispatch('GetUser')

      // Getting the model
      const q = {
        projectId: this.project.id,
        modelId: this.modelId
      }
      await this.$store.dispatch('GetModel', q)

      // Load the transactions
      await this.loadItems()
      
      await this.$store.dispatch('ListEndUsersUsage', q)

      this.loadChart()

      this.$nextTick(() => {
        this.tableKey = Date.now()
      })
    },    
    async loadItems() {
      if (!this.project) {
        return
      }

      let q = {
        projectId: this.project.id,
        pageSize: this.pagination.pageSize,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
        modelId: this.modelId,
        user: this.user,
      }

      await this.$store.dispatch('ListEndUserTransactions', q)
    },
    copyToClipboard(value) {
      this.$store.dispatch('Notify', `Copied!`)
      this.$clipboard(value)
    },
    loadChart() {
      // Get labels from usage
      let date = []
      let costs = []

      for (let i = 0; i < this.dailyCosts.length; i++) {
        // Convert date string "2023-12-28T00:00:00Z" into day and month, i.e. "28th Dec"
        let d = new Date(this.dailyCosts[i].date)
        let day = d.getDate()
        let month = d.toLocaleString('default', { month: 'short' })
        date.push(`${day} ${month}`)

        // Gets abs value of cost
        costs.push(Math.abs(this.dailyCosts[i].totalAmount.toFixed(4)))
      }

      new Chart( "#frost-chart", {                    
        data: {
          labels: date,

        datasets: [
            {
              name: "Total spend", chartType: 'bar',
              values: costs
            },
          ],
        },

        // title: `Daily Charges (${Math.abs(this.dailyCostsTotal.toFixed(2))} credits)`,
        type: 'bar', // or 'bar', 'line', 'pie', 'percentage'
        height: 300,
        colors: ['#0ea5e9'],

        axisOptions: {
          xAxisMode: "tick",
          xIsSeries: true
        },

        tooltipOptions: {
          formatTooltipX: d => (d + ''),
          formatTooltipY: d => d + ' credits',
        }
      });      
    }
  }
}
</script>
